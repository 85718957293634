import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  TextField,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import animationData from "../assets/empty-cart.lottie";
import AddIcon from "@mui/icons-material/Add";
import emptyCart from "../assets/empty-cart.png";
import RemoveIcon from "@mui/icons-material/Remove";
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import "./CartPage.css";
import Header from "../component/Header/Header";
import DialogComp from "../component/ModalComp/DialogComp";
import Footer from "../component/Footer/Footer";
import MyBasket from "../component/MyBasket/MyBasket";
import RecommendedItems from "../component/OffersAndRecommendation/RecommendedItems";
import dummy from "../assets/DummyImage.png";
import { red } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { removeItem, increaseQty, decreaseQty, addItem, addCart, removeOffer, addItemInstruction} from "../redux/slice/CartSlice";
import OfferContext from "../context/OfferContext";
import ErrorContext from "../context/ErrorContext";
import DiscountCard from "../component/DiscountCard/DiscountCard";
import OfferAppliedNotification from "../component/OfferAppliedNotification/OfferAppliedNotification";
import BackIcon from "../assets/left.png";

const CartItem = ({ index, data, onRemove, onAdd, onDelete, currSymbol }) => {
    const [instructions, setInstructions] = useState(data.instructions || "");
    const [showInput, setShowInput] = useState(false);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     setShowInput(data.instructions ? true : false);
    // }, [data.instructions]);

    // const handleInputChange = (event) => {
    //     setInstructions(event.target.value);
    // };

    // const handleIconClick = () => {
    //     setShowInput(!showInput);
    // };

    // const handleInstructionAdd = (event) => {
    //     const instruction = event.target.value;
    //     dispatch(addItemInstruction({ data, instruction }));
    // };

    return (
        <Paper
            className="cart-line-item"
            elevation={3}
            style={{
                marginBottom: 16,
                padding: 16,
                backgroundColor:
                    data?.hasInvalidOptions || data?.isInvalidProduct ? "#fdc6c6" : "#fff",
            }}
        >
            <Grid container alignItems="center" className="justification-cart">
                <Grid item xs={12} sm={6}>
                    <div className="namevgiconset">
                        <div className={data.foodType == 1 ? "veg-flag" : "non-vegflag"}>
                            <span></span>
                        </div>
                        <Typography variant="h6">{data.title}</Typography>
                    </div>
                    <Typography variant="caption" style={{ fontStyle: "italic", color: "#159f15" }}>
                        {data.offerName}
                    </Typography>
                    {/* <Typography variant="body2">
          {data.description ? data.description : ''}
        </Typography> */}
                    <div className="main-portion-box">
                        {data.optionsToAdd &&
                            data.optionsToAdd.length > 0 &&
                            data?.optionsToAdd[0]?.title && (
                                <div className="portion-box">
                                    <div className="Variations-box">
                                        {" "}
                                        <Typography className="title-sub">
                                            Variations:
                                        </Typography>{" "}
                                        {data.optionsToAdd[0].title}
                                    </div>{" "}
                                    <div className="price-box">
                                        {currSymbol}
                                        {data.optionsToAdd[0].price}
                                    </div>
                                </div>
                            )}
                        {data.optionsToAdd && data.optionsToAdd.length - 1 > 0 && (
                            <div className="main-portion-box">
                                <Typography className="title-sub">Add-ons:</Typography>{" "}
                                {data.optionsToAdd.slice(1).map((option, index) => (
                                    <div className="portion-box" key={index}>
                                        <div className="Variations-box">{option.title}-</div>{" "}
                                        <div className="price-box">
                                            {currSymbol}
                                            {option.price}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item xs={4} sm={2}>
                    {/* <Typography variant="body1">{currSymbol} {data.totalPrice * data.quantity}</Typography>
        <Typography variant="body1">{currSymbol} {(data.totalPrice * data.quantity)*(Number(data.discount)/100)}</Typography> */}
                    {data.offerId > 0 ? (
                        <div style={{ textAlign: "center" }}>
                            <Typography
                                variant="caption"
                                style={{ textDecoration: "line-through", color: "gray" }}
                            >
                                {currSymbol} {(data.totalPrice * data.quantity).toFixed(2)}
                            </Typography>
                            <Typography variant="h6" style={{ color: "green" }}>
                                {currSymbol}{" "}
                                {data.optionsToAdd.length > 0
                                    ? parseFloat(
                                          (
                                              (data.totalPrice -
                                                  data.optionsToAdd
                                                      .slice(1)
                                                      .reduce((acc, mod) => acc + mod.price, 0)) *
                                                  data.quantity *
                                                  (1 - Number(data.discount) / 100) +
                                              data.optionsToAdd
                                                  .slice(1)
                                                  .reduce((acc, mod) => acc + mod.price, 0) *
                                                  data.quantity
                                          ).toFixed(2)
                                      )
                                    : parseFloat(
                                          (
                                              data.totalPrice *
                                              data.quantity *
                                              (1 - Number(data.discount) / 100)
                                          ).toFixed(2)
                                      )}
                            </Typography>
                            {/* <Typography variant="caption" style={{ color: "red" }}>
                                {parseFloat(Number(data.discount).toFixed(2))}% off
                            </Typography> */}
                        </div>
                    ) : (
                        <Typography variant="body1">
                            {currSymbol} {(data.totalPrice * data.quantity).toFixed(2)}
                        </Typography>
                    )}
                </Grid>
                <Grid
                    item
                    xs={8}
                    sm={4}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{
                        width: "130px",
                        flex: "auto",
                        justifyContent: "Space-Between",
                        maxWidth: "130px",
                        borderRadius: "50px",
                        background: "#ecedea",
                    }}
                >
                    <IconButton onClick={onRemove} className="remove-button">
                        <RemoveIcon />
                    </IconButton>
                    <Typography
                        variant="body1"
                        style={{
                            width: "30px",
                            textAlign: "center",
                            backgroundColor: "#EFEFEF",
                            borderRadius: "8px",
                            margin: "0px 10px",
                            padding: "10px 0px",
                        }}
                    >
                        {data.quantity}
                    </Typography>
                    <IconButton onClick={onAdd} className="add-button">
                        <AddIcon />
                    </IconButton>
                </Grid>
                <Grid>
                    <IconButton onClick={onDelete}>
                        <DeleteIcon style={{ color: "red" }} />
                    </IconButton>
                </Grid>
            </Grid>
            {/* <Box
                sx={{ display: "flex", alignItems: "flex-end" }}
                className="item-instruction-style"
            >
                <EditNoteRoundedIcon
                    sx={{ mr: 1, my: 0.5, cursor: "pointer" }}
                    onClick={handleIconClick}
                />
                {showInput && (
                    <TextField
                        id="input-with-sx"
                        // label="Item Instructions"
                        placeholder="Item Instructions"
                        variant="standard"
                        className="item-instruction-input"
                        onChange={handleInputChange}
                        onBlur={handleInstructionAdd}
                        value={instructions}
                        autoComplete="off"
                    />
                )}
            </Box> */}
        </Paper>
    );
};

const CartPage = () => {
  const {apiError, setApiError, clearApiError}= useContext(ErrorContext);
  const getTotalDiscount = useSelector((state) => state.cart?.totalDiscount);
  const getStoreData = useSelector((state) => state.store.storeData);
  const [message, setMessage] = useState('');
  const [instruction, setInstruction] = useState("");
  const [severity, setSeverity] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogItem, setDialogItem] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = React.useState(false);
  const totalDiscount = useSelector((state) => state.cart?.totalDiscount);
  const offerCtx = useContext(OfferContext);
  const navigate = useNavigate();
  const recommendedItems = JSON.parse(localStorage.getItem('recommended'));

  const dispatch = useDispatch();

  useEffect(() => {
      window.scrollTo({
          top: 0,
          behavior: "smooth",
      });
  }, []);

  const handleBack = () => {
    navigate('/catalogue');
  }

  const handleRemove = (item) => {
    offerCtx.resetOffer();
    
    const offerData = {
        offername: "",
        offerdescription: "",
        isofferapplied: false
    }
    localStorage.setItem('offerData', JSON.stringify(offerData));
    dispatch(removeOffer())
    dispatch(decreaseQty(item))
  };

  const handleAdd = (item) => {
    offerCtx.resetOffer();
    
    const offerData = {
        offername: "",
        offerdescription: "",
        isofferapplied: false
    }
    localStorage.setItem('offerData', JSON.stringify(offerData));
    dispatch(removeOffer())
    dispatch(increaseQty(item))
  };

  const handleDelete = (item) => {
    offerCtx.resetOffer();
    
    const offerData = {
        offername: "",
        offerdescription: "",
        isofferapplied: false
    }
    localStorage.setItem('offerData', JSON.stringify(offerData));
    dispatch(removeOffer())
    dispatch(removeItem(item))
  };

  const handleInstructionChange = (e) => {
      setInstruction(e.target.value);
  };

  // This function will be called when the input loses focus (onBlur)
  const handleInstructionSave = () => {
      const instructionData = instruction;
      localStorage.setItem("userInstructions", instructionData);
  };


  let cartItems = useSelector((state)=>state.cart.cartData);
  cartItems = cartItems?.length > 0 ? cartItems : JSON.parse(localStorage.getItem('cartData')) || [];

  useEffect(() => {
    const cartdata =  JSON.parse(localStorage.getItem('cartData'));

    if (!cartdata || cartdata.length === 0) {
      return;
    }

    dispatch(addCart(cartdata));
  }, [])

  useEffect(() => {
    const resizeObserverError = (event) => {
        if (event.message === 'ResizeObserver loop completed with undelivered notifications.') {
            event.stopImmediatePropagation();
        }
    };
    window.addEventListener('error', resizeObserverError);
    return () => {
        window.removeEventListener('error', resizeObserverError);
    };
}, []);

    const convertOptionToPlainObject = (option) => {
      if (option && Object.keys(option).length === 0 && option.constructor === Object) {
          return {};
      }

      if (option instanceof Object) {
          return {
              refId: option.refId,
              title: option.title,
              groupRefID: option.groupRefID,
              groupTitle: option.groupTitle,
              description: option.description,
              weight: option.weight,
              available: option.available,
              price: option.price,
              markupPrice: option.markupPrice,
              soldAtStore: option.soldAtStore,
              sortOrder: option.sortOrder,
              optGroupRefIds: [option?.optGroupRefIds],
              nestedOptGroups: [option?.nestedOptGroups],
              foodType: option.foodType
          };
      }
      return option;
    };

    const addProductToCart = (item, firstVariationPrice) => {
      if (item.available == 1 || item.available == true) {
          if(item?.optionsToAdd?.length > 0 ? false : item.optionGroups.length > 0 ){
              setOpenDialog(true);
              setDialogItem(item);
          }else{
              setShowPopup(true);
              // setMessage('Product added to cart successfully!');
              // setSeverity('success');
              const itemData = {
                  available: item.available,
                  currentStock: Number(item.currentStock),
                  description: item.description,
                  foodType: item.foodType,
                  imgUrl: item.imgUrl,
                  markupPrice: item.markupPrice,
                  // optionGroups: item.optionGroups ? item.optionGroups.map(group => convertToPlainObject(group)) : [],
                  price: item.price,
                  recommended: item.recommended,
                  refId: Number(item.refId),
                  title: item.title,
                  categoryRefIds: item.categoryRefIds,
                  soldAtStore: item.soldAtStore,
                  sortOrder: item.sortOrder,
                  weight: item.weight,
                  quantity: item.quantity || 1,
                  totalPrice: Number(
                      item.price === 0
                          ? firstVariationPrice
                          : item.price === firstVariationPrice
                          ? item.price
                          : item.price + firstVariationPrice
                  ),
                  itemTotalTax: 0,
                  offerId: 0,
                  offerName: "",
                  discount: 0,
                  productPortionId: 0,
                  taxProfileId: 0,
                  portionName: null,
                  optionsToAdd: item.optionsToAdd
                      ? item.optionsToAdd.map((option) => convertOptionToPlainObject(option))
                      : [],
              };
              
              if(totalDiscount > 0) {
                  offerCtx.resetOffer();

                  const offerData = {
                      offername: "",
                      offerdescription: "",
                      isofferapplied: false
                  }
                  localStorage.setItem('offerData', JSON.stringify(offerData));
                  dispatch(removeOffer());
                  dispatch(addItem(itemData));
              } else {
                  dispatch(addItem(itemData));
              }

              setOpen(true);
          }
        } else {
          setMessage('Product is not available!');
          setSeverity('error');
        }
        item.optionsToAdd = [];
    };
  

  return (
      <div>
          <Header />
          <Container>
              <Box my={4} className="bottom-margin-cart">
                  <div className="cart-container">
                      <div className="cart-items">
                          {getTotalDiscount > 0 && <OfferAppliedNotification />}
                          <div className="backpart">
                              <div className="backiconset">
                                  <img src={BackIcon} alt="Back" onClick={handleBack} />
                              </div>
                              <div className="brandnameback">
                                  <h3>Back</h3>
                              </div>
                          </div>
                          {/* <span style={{ color: "red" }}>{apiError}</span> */}
                          {cartItems && cartItems.length > 0 ? (
                              <>
                                  {cartItems.map((item, index) => (
                                      <CartItem
                                          key={index}
                                          data={item}
                                          onRemove={() => handleRemove(item)}
                                          onAdd={() => handleAdd(item)}
                                          onDelete={() => handleDelete(item)}
                                          currSymbol={getStoreData?.currencysymbol}
                                      />
                                  ))}
                                  <Box
                                      mt={2}
                                      p={2}
                                      component={Paper}
                                      elevation={3}
                                      className="input-box-setup"
                                  >
                                      <Grid container alignItems="center">
                                          <Grid item>
                                              <IconButton>
                                                  <EditNoteRoundedIcon />
                                              </IconButton>
                                          </Grid>
                                          <Grid item xs>
                                              <TextField
                                                  fullWidth
                                                  placeholder="Mention your special instructions here..."
                                                  variant="outlined"
                                                  multiline
                                                  onChange={(e) => handleInstructionChange(e)}
                                                  onBlur={handleInstructionSave}
                                              />
                                          </Grid>
                                      </Grid>
                                  </Box>
                                  <div className="cart-recommendeditems">
                                      {recommendedItems?.length > 0 && (
                                          <Typography>Complete your meal</Typography>
                                      )}
                                      <div className="cart-featured-scroller">
                                          {recommendedItems?.map((item) => (
                                              <div key={item.refId} className="featured-card">
                                                  <div className="image-container">
                                                      <img
                                                          src={
                                                              item.imgUrl &&
                                                              item.imgUrl !== null &&
                                                              item.imgUrl !== ""
                                                                  ? item.imgUrl
                                                                  : dummy
                                                          }
                                                          onError={(e) => {
                                                              e.target.src = dummy;
                                                          }}
                                                          alt={item.title}
                                                          className="recommendedproduct-image"
                                                          onClick={() =>
                                                              addProductToCart(
                                                                  item,
                                                                  item.price
                                                                      ? item.price
                                                                      : item?.optionGroups.find(
                                                                            (el) =>
                                                                                el.refId ==
                                                                                item.refId
                                                                        )?.options[0].price
                                                              )
                                                          }
                                                          style={{ cursor: "pointer" }}
                                                      />
                                                      {/* {item.customizable && (
                                <span className="customizable-tag">Customizable</span>
                            )} */}
                                                  </div>
                                                  <div className="product-info">
                                                      <p
                                                          className="product-name"
                                                          onClick={() =>
                                                              addProductToCart(
                                                                  item,
                                                                  item.price
                                                                      ? item.price
                                                                      : item?.optionGroups.find(
                                                                            (el) =>
                                                                                el.refId ==
                                                                                item.refId
                                                                        )?.options[0].price
                                                              )
                                                          }
                                                          style={{ cursor: "pointer" }}
                                                      >
                                                          {item.title}
                                                      </p>
                                                      <div className="p-b-setup">
                                                          <div
                                                              onClick={() =>
                                                                  addProductToCart(
                                                                      item,
                                                                      item.price
                                                                          ? item.price
                                                                          : item?.optionGroups.find(
                                                                                (el) =>
                                                                                    el.refId ==
                                                                                    item.refId
                                                                            )?.options[0].price
                                                                  )
                                                              }
                                                              style={{ cursor: "pointer" }}
                                                          >
                                                              <p className="product-price">
                                                                  {getStoreData?.currencysymbol}{" "}
                                                                  {item.price
                                                                      ? item.price
                                                                      : item?.optionGroups.find(
                                                                            (el) =>
                                                                                el.refId ==
                                                                                item.refId
                                                                        )?.options[0].price}
                                                              </p>
                                                          </div>
                                                          <div>
                                                              <button
                                                                  className="add-to-cart-btn"
                                                                  onClick={() =>
                                                                      addProductToCart(
                                                                          item,
                                                                          item.price
                                                                              ? item.price
                                                                              : item?.optionGroups.find(
                                                                                    (el) =>
                                                                                        el.refId ==
                                                                                        item.refId
                                                                                )?.options[0].price
                                                                      )
                                                                  }
                                                              >
                                                                  +
                                                              </button>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          ))}
                                      </div>
                                  </div>
                              </>
                          ) : (
                              <div className="empty-cart">
                                  {/* <img src={emptyCart} alt="Empty Cart Image" /> */}
                                  <div style={{ width: "250px", height: "250px" }}>
                                      <DotLottieReact src={animationData} loop autoplay />
                                  </div>

                                  <h1>Cart is currently empty</h1>
                                  <p>Looks like you haven't made your choices yet...</p>
                              </div>
                          )}
                      </div>
                      <div className="set-rt">
                          {/* <DiscountCard /> */}
                          <MyBasket />
                      </div>
                  </div>
              </Box>
          </Container>
          {openDialog && (
              <DialogComp
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
                  dialogItem={dialogItem}
                  addProductToCart={addProductToCart}
              />
          )}
          {/* <Footer /> */}
      </div>
  );
};

export default CartPage;
