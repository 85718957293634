import React, { useEffect, useState } from "react";
import { Drawer, Box, Typography } from "@mui/material";
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import "./OfferDrawerComp.css";

function OfferDrawerComp({ isDrawerOpen, setIsDrawerOpen, offers }) {
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 600) {
                setIsDrawerOpen(false); // Auto-close the drawer
            }
        };

        window.addEventListener("resize", handleResize);

        // Cleanup event listener when component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setIsDrawerOpen]);

    const toggleReadMore = (index) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <Drawer
            anchor="bottom"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            PaperProps={{
                className: "custom-offer-drawer",
            }}
        >
            <Box
                className="drawer-offer-box"
                sx={{
                    textAlign: "left",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: 300,
                        overflowY: "auto",
                        overflowX: "hidden",
                        // padding: 2,
                    }}
                >
                    {offers.length > 0 ? (
                        offers.map((offer, index) => {
                            const isExpanded = expandedDescriptions[index];
                            const shouldShowReadMore = offer.offerdescription.length > 80;

                            return (
                                <Box
                                    key={index}
                                    // onClick={() => handleSelect(location)}
                                    sx={{
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                        backgroundColor: "transparent",
                                        "&:hover": {
                                            boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                                        },
                                    }}
                                >
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            padding: "10px",
                                            border: "1px solid #ccc",
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <div className="ssl-icon" style={{ marginRight: "10px" }}>
                                            <span style={{ color: "green" }}>
                                                <LocalOfferRoundedIcon />
                                            </span>
                                        </div>
                                        <div className="ssl-content">
                                            <Typography
                                                variant="h6"
                                                className="sm-offer-name"
                                                style={{ marginBottom: "5px" }}
                                            >
                                                {offer.offername}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                className="sm-offer-address"
                                                color="textSecondary"
                                                style={{ marginBottom: "8px" }}
                                            >
                                                {isExpanded
                                                    ? offer.offerdescription
                                                    : `${offer.offerdescription.slice(0, 80)}${
                                                          shouldShowReadMore ? "..." : ""
                                                      }`}
                                                {shouldShowReadMore && (
                                                    <span
                                                        onClick={() => toggleReadMore(index)}
                                                        className="read-more-offer"
                                                    >
                                                        {isExpanded ? " Read Less" : " Read More"}
                                                    </span>
                                                )}
                                            </Typography>
                                        </div>
                                    </div>
                                </Box>
                            );
                        })
                    ) : (
                        <div className="no-search-found">
                            <Typography variant="body2">
                                {`No Result Found For `}
                                {/* <span className="no-search-found-span">"{searchQuery}"</span> */}
                            </Typography>
                        </div>
                    )}
                </Box>
            </Box>
        </Drawer>
    );
}

export default OfferDrawerComp;
