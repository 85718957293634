import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import dummy from "../../assets/DummyImage.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./DialogComp.css";
import { useSelector } from "react-redux";
import { dialog } from "framer-motion/client";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        backgroundColor: "#f5f5f5", // Set the dialog background color
        borderRadius: "16px",
        width: "50%", // Set your desired width
        maxWidth: "60%",

        [theme.breakpoints.down(900)]: {
            width: "80%",
            maxWidth: "90%",
        },
        "@media (min-width: 1024px) and (max-width: 1500px)": {
            width: "60%",
            maxWidth: "70%",
        },

        "@media (min-width: 900px) and (max-width: 1024px)": {
            width: "70%",
            maxWidth: "80%",
        },
        
        // Smaller screen settings for even tighter widths
        [theme.breakpoints.down("sm")]: {
            width: "90%",
            maxWidth: "95%",
        },
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
    },
}));

const ModifierPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: "#ffffff",
    borderRadius: "8px",
}));

const VariationPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: "#ffffff",
    borderRadius: "8px",
}));

export default function DialogComp({ openDialog, setOpenDialog, dialogItem, addProductToCart }) {
    const [selectedPortion, setSelectedPortion] = useState({});
    const [selectedModifiers, setSelectedModifiers] = useState([]);
    const [addedModifiers, setAddedModifiers] = useState([]);
    const [portions, setPortions] = useState([]);
    const [modifiers, setModifiers] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("warning");

    const getStoreData = useSelector((state) => state.store.storeData)

    useEffect(() => {
        if (openDialog) {
            const portionOptions = dialogItem.optionGroups.filter(
                (group) => group.refId === dialogItem.refId
            );
            const modifierOptions = dialogItem.optionGroups.filter(
                (group) => group.refId !== dialogItem.refId
            );

            if (portionOptions?.length > 0) {
                setPortions(portionOptions[0].options);
                setSelectedPortion(portionOptions[0].options[0]);

                const nestedOptGroups = portionOptions[0].options[0].nestedOptGroups;
                const selectedmod = modifierOptions.filter((m) =>
                    nestedOptGroups.includes(m.refId)
                );

                if (modifierOptions?.length > 0) {
                    setModifiers(modifierOptions);
                    setSelectedModifiers(selectedmod);

                    // Auto-select modifiers where minSelectable === maxSelectable
                    let autoSelectedModifiers = [];
                    selectedmod.forEach((group) => {
                        if (group.minSelectable === group.maxSelectable) {
                            autoSelectedModifiers = [
                                ...autoSelectedModifiers,
                                ...group.options
                                    .filter((option) => option.available === true)
                                    .slice(0, group.maxSelectable),
                            ];
                        }
                    });
                    setAddedModifiers(autoSelectedModifiers);
                }
            } else {
                setSelectedPortion({});
                setModifiers(modifierOptions);
                setSelectedModifiers(modifierOptions);
                // setAddedModifiers(modifierOptions[0].options);

                // Auto-select modifiers where minSelectable === maxSelectable
                let autoSelectedModifiers = [];
                modifierOptions.forEach((group) => {
                    if (group.minSelectable === group.maxSelectable) {
                        autoSelectedModifiers = [
                            ...autoSelectedModifiers,
                            ...group.options.slice(0, group.maxSelectable),
                        ];
                    }
                });
                setAddedModifiers(autoSelectedModifiers);
            }
        }
    }, [openDialog]);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handlePortionChange = (refId) => {
        const newSelectedPortion = portions.find((portion) => portion.refId === refId);
        const nestedOptGroups = newSelectedPortion.nestedOptGroups;
        const updatedModifiers = modifiers.filter((m) => nestedOptGroups.includes(m.refId));

        let preservedModifiers = [];

        // Auto-select modifiers where minSelectable === maxSelectable
        updatedModifiers.forEach((group) => {
            if (group.minSelectable === group.maxSelectable && group.minSelectable > 1 && group.maxSelectable > 1) {
                const alreadySelectedInGroup = preservedModifiers.filter(
                    (m) => m.optGroupRefIds[0] === group.refId
                );

                if (alreadySelectedInGroup.length < group.maxSelectable) {
                    const newSelections = group.options
                        .slice(0, group.maxSelectable)
                        .filter(
                            (option) => !preservedModifiers.some((m) => m.refId === option.refId)
                        );

                    preservedModifiers = [...preservedModifiers, ...newSelections];
                }
            }
        });

        setSelectedPortion(newSelectedPortion);
        setSelectedModifiers(updatedModifiers);
        setAddedModifiers(preservedModifiers);
    };

    const handleModifierChange = (option) => {
        const isSelected = addedModifiers.some((modifier) => modifier.refId === option.refId);
        const maxSelectable = selectedModifiers.find(
            (group) => group.refId === option.optGroupRefIds[0]
        ).maxSelectable;

        const addedModifierQuantity = addedModifiers.filter(
            (m) => m.optGroupRefIds[0] === option.optGroupRefIds[0]
        ).length;

        if (!isSelected && addedModifierQuantity >= maxSelectable) {
            setSnackbarMessage(`You can only select up to ${maxSelectable} add ons.`);
            setSnackbarSeverity("warning");
            setSnackbarOpen(true);

            return;
        }

        if (isSelected) {
            setAddedModifiers((prev) => prev.filter((modifier) => modifier.refId !== option.refId));
        } else {
            setAddedModifiers((prev) => [...prev, option]);
        }
    };

    const handleAddToCart = () => {
        // Handle adding the product with selected portions and modifiers to the cart
        let isValid = true;
        if(Number(selectedPortion?.refId) > 0 && (selectedPortion?.available == "false" || selectedPortion?.available == false)){
            setSnackbarMessage(`${selectedPortion.title} is not available yet. Please select another portion`);
            setSnackbarSeverity("warning");
            setSnackbarOpen(true);
            return;
        }

        for (let modifierGroup of selectedModifiers) {
            const selectedCount = addedModifiers.filter(
                (modifier) => modifier.optGroupRefIds[0] === modifierGroup.refId
            ).length;

            if (selectedCount < modifierGroup.minSelectable) {
                setSnackbarMessage(
                    `You must select at least ${modifierGroup.minSelectable} options from ${modifierGroup.title}.`
                );
                setSnackbarSeverity("warning");
                setSnackbarOpen(true);
                isValid = false;
                break;
            }
        }

        if (isValid) {
            let array = [];
            array.splice(0, 0, selectedPortion);
            array = array.concat(addedModifiers);
            dialogItem.optionsToAdd = array;
            // dialogItem.optionsToAdd.push(array);
            const modifierPrice = addedModifiers.reduce(
                (total, modifier) => total + modifier.price,
                0
            );

            addProductToCart(dialogItem, (array[0]?.price || 0) + modifierPrice);
            setSelectedPortion({});
            setSelectedModifiers([]);
            setAddedModifiers([]);
            setPortions([]);
            setModifiers([]);
            handleClose();
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                className="model-backdrop"
            >
                <DialogContent dividers>
                    <div className="popup-main">
                        <div className="popup-img-setup">
                            <div style={{ position: "relative" }}>
                                <img
                                    src={
                                        dialogItem.imgUrl &&
                                        dialogItem.imgUrl !== null &&
                                        dialogItem.imgUrl !== ""
                                            ? dialogItem.imgUrl
                                            : dummy
                                    }
                                    onError={(e) => {
                                        e.target.src = dummy;
                                    }}
                                    alt="product image"
                                    style={{
                                        borderRadius: "16px",
                                        width: "100%",
                                        height: "auto",
                                        display: "block",
                                        margin: "0 auto",
                                    }}
                                />

                                <div>
                                    <div>
                                        <div
                                            className={
                                                dialogItem.foodType == 1
                                                    ? "veg-flag vnf"
                                                    : "non-vegflag vnf"
                                            }
                                        >
                                            <span></span>
                                        </div>
                                        <h3 className="popup-title-set">{dialogItem.title}</h3>
                                    </div>
                                    <div className="popup-discription">
                                        <p>{dialogItem.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup-addons-content-box">
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    top: 8,
                                    right: 8,
                                    color: "black",
                                    backgroundColor: "white",
                                    borderRadius: "50%",
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            {portions.length > 0 && (
                                <div className="vriyebal-name">
                                    <Typography variant="subtitle1" gutterBottom>
                                        Variants
                                    </Typography>
                                </div>
                            )}
                            {portions.length > 0 && (
                                <div className="veriant-main">
                                    <RadioGroup
                                        aria-label="portion"
                                        name="portion"
                                        value={selectedPortion.refId}
                                    >
                                        <Grid container spacing={1}>
                                            {portions &&
                                                portions.map((portion) => (
                                                    <Grid item xs={4} key={portion.refId}>
                                                        <VariationPaper
                                                            elevation={1}
                                                            key={portion.refId}
                                                            onClick={() =>
                                                                handlePortionChange(portion.refId)
                                                            }
                                                            className={`portion-tile ${
                                                                selectedPortion.refId ===
                                                                portion.refId
                                                                    ? "selected"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <Typography
                                                                align="center"
                                                                className="portion-title-dm"
                                                                sx={{fontSize: "0.9rem"}}
                                                            >
                                                                {portion.title}
                                                            </Typography>
                                                            <Typography align="center" sx={{fontSize: "0.9rem"}}>
                                                                {getStoreData?.currencysymbol}{" "}
                                                                {portion.price}
                                                            </Typography>
                                                        </VariationPaper>
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </RadioGroup>
                                </div>
                            )}

                            {selectedModifiers.length > 0 && (
                                <div className="vriyebal-name">
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        className="add-ons-dialogue"
                                    >
                                        Add ons
                                    </Typography>
                                </div>
                            )}
                            {selectedModifiers
                                .sort((a, b) => a.sortOrder - b.sortOrder)
                                .map((modifierGroup) => (
                                    <div className="setbgpor" key={modifierGroup.refId}>
                                        <div className="set-addportion-box">
                                            <div className="addportion-t-box new-seprator">
                                                <div className="new-seprators">
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {modifierGroup.title}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        gutterBottom
                                                    >
                                                        You can choose up to{" "}
                                                        {modifierGroup.maxSelectable} option(s)
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                        {modifierGroup.options.map((option) => {
                                            const isAvailable =
                                                option.available === true ||
                                                option.available === "true";

                                            return (
                                                <ModifierPaper
                                                    elevation={1}
                                                    key={option.refId}
                                                    onClick={() =>
                                                        isAvailable && handleModifierChange(option)
                                                    }
                                                    className={`modifier-tile ${
                                                        addedModifiers.some(
                                                            (modifier) =>
                                                                (modifier.refId === option.refId) && isAvailable
                                                        )
                                                            ? "selected"
                                                            : ""
                                                    }`}
                                                    style={{
                                                        // userSelect: "none", // Prevents text selection
                                                        // outline: "none",    // Removes focus outline
                                                        WebkitTapHighlightColor: "transparent", // Removes blue highlight on touch
                                                        opacity: isAvailable ? 1 : 0.6, // Make disabled options slightly faded
                                                        cursor: isAvailable
                                                            ? "pointer"
                                                            : "not-allowed",
                                                    }}
                                                >
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        key={option.refId}
                                                        style={{ padding: "7px" }}
                                                    >
                                                        <Grid item xs={8}>
                                                            <Typography>{option.title}</Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography align="right">
                                                                {getStoreData?.currencysymbol}{" "}
                                                                {option.price}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Checkbox
                                                                checked={addedModifiers.some(
                                                                    (modifier) =>
                                                                        (modifier.refId ===
                                                                        option.refId) && isAvailable
                                                                )}
                                                                value={option.title}
                                                                disabled={!isAvailable}
                                                                // onChange={() => handleModifierChange(option)}
                                                                // disabled={
                                                                //     portions.length === 0 &&
                                                                //     selectedModifiers[0].maxSelectable ===
                                                                //         selectedModifiers[0].minSelectable
                                                                // }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </ModifierPaper>
                                            );
                                        })}
                                    </div>
                                ))}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={handleAddToCart}
                        sx={{
                            backgroundColor: "green",
                            color: "white",
                            "&:hover": { backgroundColor: "darkgreen" },
                        }}
                    >
                        Add to Cart
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}
