import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import "./MobilePromo.css";

const MobilePromo = ({ offers, setIsDrawerOpen }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (offers.length === 0) return;

        const interval = setInterval(() => {
            setCurrentIndex((prev) => (prev + 1) % offers.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [offers.length]);

    useEffect(() => {
        setCurrentIndex(0);
    }, [offers]);

    return (
        // <Box className="mobile-promo">
        //     <Box className="promo-box">
        //         <Typography variant="body1" className="promo-text">
        //             {offers[currentIndex]?.description || "Limited Time Offer!"}
        //         </Typography>
        //     </Box>
        //     <Typography variant="body2" className="offer-count">
        //         {offers.length} offers ▼
        //     </Typography>
        // </Box>

        <Box className="promo-container">
            <div>
                <h2 className="offers-title divider line one-line">
                    <span>OFFERS</span>
                </h2>
                <div className="mobile-promo" onClick={() => setIsDrawerOpen(true)} style={{cursor: "pointer"}}>
                    <div className="offers-container">
                        {offers.length > 0 && (
                            <div className="offer-item" key={currentIndex}>
                                <span className="offer-name">{offers[currentIndex].offername}</span>
                            </div>
                        )}
                    </div>
                    <Typography variant="body2" className="offer-count">
                        <div className="offer-count-container">
                            {offers.length} offers <ExpandMoreRoundedIcon />
                        </div>
                    </Typography>
                </div>
            </div>
        </Box>
    );
};

export default MobilePromo;
